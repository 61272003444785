import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { getTestProps } from '@mwl/marker-tree';
import ArrowLeftDouble from '@public/assets/common/icons/arrowLeftDouble.svg';
import { commonObject } from '@/utils';
import { Button } from '../Button/Button';
import type { GamesGridLayoutProps } from './GamesGridLayout.types';
import styles from './GamesGridLayout.module.scss';
export const GamesGridLayout: FC<GamesGridLayoutProps> = ({
  title,
  href,
  id,
  children,
  className,
  classes,
  isShowItemsCount = true,
  totalGamesCount,
  headerVariant = 'uppercase',
  ...props
}) => {
  const {
    t
  } = useTranslation('casino');
  return <div id={id} className={cn(styles.root, className)} {...getTestProps(props)} data-sentry-component="GamesGridLayout" data-sentry-source-file="GamesGridLayout.tsx">
      {title && <div className={cn(styles.header, classes?.header)}>
          <h2 {...commonObject.gamesGridLayout.title.nodeProps} className={cn(styles.title, styles[`${headerVariant}HeaderVariant`])}>
            <span className={styles.text}>{title}</span> {isShowItemsCount && <sup>{totalGamesCount}</sup>}
          </h2>
          {href && <Button {...commonObject.gamesGridLayout.allButton.nodeProps} as="link" href={href} color="white" variant="fulfilled" cut={false} className={styles.moreButton} fontWeight="bold">
              {t('common:all')}
              <ArrowLeftDouble width={14} height={12} />
            </Button>}
        </div>}
      {children}
    </div>;
};
export * from './GamesGridLayout.types';